import { Link, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import * as styles from './header.module.scss';
import Logo from '@/assets/icons/logo.inline.svg';
import { useTranslation } from '@/src/contexts/translation.context';
import BottomSheet from '../bottom-sheet/bottom-sheet';
import SignupFunnel from '../signup-funnel/signup-funnel';
import useAuthStatus from '@/src/hooks/auth-status.hook';
import CookieConsent from "react-cookie-consent";

export default function Header() {
  const translation = useTranslation();
  const [href, setHref] = useState('');
  const [funnelOpen, setFunnelOpen] = useState(false);
  const { isAuthenticated } = useAuthStatus();

  useEffect(() => {
    setHref(window.location.href);
  }, []);

  const handleClick = () => {
    if(!isAuthenticated) {
      setFunnelOpen(true);
    } else {
      navigate(`/${translation.lang}/purchase/cart`);
    }
  };

  const closeFunnel = () => {
    setFunnelOpen(false);
  };

  const changeLang = (event: any) => {
    const path = window.location.pathname;
    const newPath = path.replace(`/${translation.lang}`, '/' + event.target.value);
    navigate(newPath);
  }

  return (
    <>
      <div className={styles.container}>
        <Link className={styles.logo} to={`/${translation.lang}`}>
          <Logo></Logo>
        </Link>

        <div className={styles.navbar}>
          <Link to={`/${translation.lang}/book-ticket`} className={(href.indexOf('book-ticket') > -1 || href.indexOf('booked-trips') > -1) ? styles['active'] : ''}>
            {translation.translate('navbar.book_ticket')}
          </Link>
          <Link to={`/${translation.lang}/activities`} className={href.indexOf('activities') > -1 ? styles['active'] : ''}>
            {translation.translate('navbar.activities')}
          </Link>
          <Link to={`/${translation.lang}/my-passes`} className={href.indexOf('my-passes') > -1 ? styles['active'] : ''}>
            {translation.translate('navbar.my_passes')}
          </Link>
          <Link to={`/${translation.lang}/profile`} className={href.indexOf('profile') > -1 ? styles['active'] : ''}>
            {translation.translate('navbar.my_profile')}
          </Link>
        </div>
        
        <div className={styles.actions}>
          <select onChange={(value) => changeLang(value)} value={translation.lang}>
            <option value="sv">Svenska</option>
            <option value="en">Engelska</option>
          </select>
          <button className={styles.button} onClick={handleClick}>{translation.translate('navbar.buy_now')}</button>
        </div>
      </div>
      <BottomSheet open={funnelOpen}>
        <SignupFunnel actionKey="buy_pass" onClose={closeFunnel}></SignupFunnel>
      </BottomSheet>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        style={{ background: "#005249", color: "white" }}
        buttonStyle={{ color: "white", background: "black", fontSize: "14px", borderRadius: "10px", padding: "5px 15px 8px" }}
        cookieName="gatsby-gdpr-google-analytics">
        This website use Cookies and similar techniques (collectively called “Cookies”) in order to improve your experience while you navigate through the website.
      </CookieConsent>
    </>
  );
}