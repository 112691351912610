import { useEffect, useState } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { AuthService } from '../services/auth.service';

export default function useAuthStatus() {
  const [ isAuthenticated, setIsAuthenticated ] = useState(false);
  const [ hasLoadedAuth, setHasLoadedAuth ] = useState(false);

  useEffect(() => {
    setHasLoadedAuth(true);
    setIsAuthenticated(new AuthService().isLoggedIn());
    firebase.auth().onAuthStateChanged(user => {
      setIsAuthenticated(!!user);
    });
  }, []);

  return { isAuthenticated, hasLoadedAuth };
}