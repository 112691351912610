import React, { useState } from 'react';
import { TranslationService } from '../services/translation.service';

const TranslationContext = React.createContext<TranslationService>(null);

type TranslationProviderProps = {
  children: JSX.Element[] | JSX.Element;
  value: TranslationService;
};

export function TranslationProvider({ children, value }: TranslationProviderProps) {
  const [ service ] = useState(value);
  return <TranslationContext.Provider value={service}>{children}</TranslationContext.Provider>;
}

export function useTranslation(): TranslationService {
  const context = React.useContext(TranslationContext);
  if(!context) {
    throw new Error('useTranslation must be used within a TranslationProvider.');
  }
  return context;
}