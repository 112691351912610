import { useTranslation } from '@/src/contexts/translation.context';
import { Link } from 'gatsby';
import React from 'react';
import * as styles from './footer.module.scss';
import PayPalIcon from '@/assets/icons/payment-methods/paypal.inline.svg';
import VisaIcon from '@/assets/icons/payment-methods/visa.inline.svg';
import MastercardIcon from '@/assets/icons/payment-methods/mastercard.inline.svg';
import AmexIcon from '@/assets/icons/payment-methods/amex.inline.svg';

type FooterProps = {
  className?: string;
};

export default function Footer({ className }: FooterProps) {
  const translation = useTranslation();

  return (
    <div className={`${styles.wrapper} ${className ? className : ''}`}>
      <div className={styles.content}>
        <Link to={`/${translation.lang}`}>
          <p className={styles.logo}>TO DO ÅLAND</p>
        </Link>

        <div className={styles.contactInformation}>
          <a href="mailto:info@todoaland.com">
            <strong>{translation.translate('footer.email')}: </strong>
            <span>info@todoaland.com</span>
          </a>
          {/*<a href="tel:+358018435678">
            <strong>{translation.translate('footer.phone')}: </strong>
            <span>+358 (0) 18 435678</span>
          </a>*/}
        </div>

        <div className={styles.creditCards}>
          <PayPalIcon></PayPalIcon>
          <VisaIcon></VisaIcon>
          <MastercardIcon></MastercardIcon>
          <AmexIcon></AmexIcon>
        </div>

        <div className={styles.disclaimer}>
          <p>Copyright © 2021 • Todo Åland • <Link to={`/${translation.lang}/terms-and-conditions`}>{translation.translate('footer.terms_and_conditions')}</Link></p>
          <p>Media credits: To Do Åland & <a href="https://mediabank.visitaland.com/" target="_blank" rel="noopener noreferrer">Visit Åland</a></p>
        </div>
      </div>
    </div>
  );
}