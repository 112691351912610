import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

type BottomSheetProps = {
  open: boolean;
  children?: JSX.Element | JSX.Element[];
};

export default function BottomSheet({ open, children }: BottomSheetProps) {
  const [ element, setElement ] = useState(null);

  useEffect(() => {
    if(typeof document !== 'undefined') {
      setElement(document.getElementById('bottom-sheet-modal'));
    }
  }, []);

  if(element) {
    return ReactDOM.createPortal(
      (
        <CSSTransition 
          in={open} 
          classNames="bottom-sheet-transition" 
          timeout={200}
          unmountOnExit>
          <div className="bottom-sheet-inner">
            {children}
          </div>
        </CSSTransition>
      ),
      element
    );
  } else {
    return null;
  }
}