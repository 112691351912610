import React from 'react';
import * as styles from './signup-funnel.module.scss';
import { IoIosClose } from 'react-icons/io';
import { LargeButton } from '../button/button';
import { Link } from 'gatsby';
import { useTranslation } from '@/src/contexts/translation.context';

type SignupFunnelProps = {
  onClose?: () => any;
  actionKey: string;
};

export default function SignupFunnel({ onClose, actionKey }: SignupFunnelProps) {
  const translation = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.headingRow}>
        <h1>{translation.translate(`signup_funnel.${actionKey}.header`)}</h1>
        <button onClick={onClose}>
          <IoIosClose size={45}></IoIosClose>
        </button>
      </div>

      <div className={styles.actionTakenRow}>
        <p>{translation.translate(`signup_funnel.${actionKey}.body`)}</p>
      </div>

      <div className={styles.ctaRow}>
        <Link to={`/${translation.lang}/signup`}>
          <LargeButton type="button">
            <span>{translation.translate('signup_funnel.create_account')}</span>
          </LargeButton>
        </Link>

        <div className={styles.divider}>
          <div></div>
          <span>{translation.translate('signup_funnel.or')}</span>
          <div></div>
        </div>

        <p className={styles.login}>
          {translation.translate('signup_funnel.already_signed_up')}
          <Link to={`/${translation.lang}/login`}>{translation.translate('signup_funnel.log_in')}</Link>
        </p>
      </div>
    </div>
  );
}