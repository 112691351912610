export class TranslationService {
  constructor(
    private pageContext: any
  ) {}

  get lang(): string {
    return this.pageContext.locale;
  }

  translate(key: string): string {
    if(typeof this.pageContext.languageDefinition === 'object') {
      const def = this._deepGet(this.pageContext.languageDefinition, key);
      if(def) {
        return def;
      } else {
        return key;
      }
    } else {
      return 'Language definition is not an object.';
    }
  }

  _deepGet(obj: any, path: string): any {
    const paths = path.split('.');
    let current = obj;
    
    for(let i=0;i<paths.length;i++) {
      if(!current[paths[i]]) {
        return undefined;
      } else {
        current = current[paths[i]];
      }
    }

    return current;
  }  
}