import React from 'react';
import * as styles from './button.module.scss';

type ButtonProps = {
  onClick?: () => any;
  type: 'submit' | 'button';
  children: JSX.Element[] | JSX.Element;
  disabled?: boolean;
}

export function LargeButton({ children, type, onClick, disabled = false }: ButtonProps) {
  return (
    <button className={`${styles.largeButton} ${disabled ? 'disabled' : ''}`} type={type} disabled={disabled} onClick={onClick}>{children}</button>
  );
}