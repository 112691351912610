import { CreateUserDTO, CreateUserRO } from '../models/create-user.model';
import firebase from 'gatsby-plugin-firebase';

export class AuthService {
  async createUser(createUserDTO: CreateUserDTO): Promise<CreateUserRO> {
    try {
      await firebase.app().functions('europe-west3').httpsCallable('createUser')(createUserDTO);
      return { success: true, message: '' };
    } catch(err) {
      return { success: false, message: err.message };
    }
  }
  async login(email: string, password: string): Promise<boolean> {
    try {
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      await firebase.auth().signInWithEmailAndPassword(email, password);
      return true;
    } catch(err) {
      console.error(err);
      return false;
    }
  }

  async logout() {
    try {
      await firebase.auth().signOut();
    } catch(err) {
      console.error(err);
    }
  }

  isLoggedIn(): boolean {
    return firebase.auth().currentUser != null;
  }
}